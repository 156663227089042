import { ContainerLg } from '/features/buildingBlocks/Container'
import { Introduction } from '/features/pageOnly/home/Introduction'
import { LocalShadowCasting } from '/features/pageOnly/home/LocalShadowCasting'
import { LocalSectionSand } from '/features/pageOnly/home/LocalSectionSand'

import styles from './IntroductionWrapper.css'

export function IntroductionWrapper({ introduction }) {
  return (
    <LocalSectionSand componentClass={styles.localSectionSand} dataX='introduction'>
      <LocalShadowCasting layoutClassName={styles.shadowCastingLayout} />

      {introduction && (
        <div className={styles.introductionContainer}>
          <ContainerLg>
            <Introduction {...{ introduction }} />
          </ContainerLg>
        </div>
      )}
    </LocalSectionSand>
  )
}
