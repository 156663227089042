import { HeadingXs, HeadingXxs } from '/features/buildingBlocks/Heading'
import { ButtonGhost } from '/features/buildingBlocks/Button'
import { useMediaQuery } from '@kaliber/use-media-query'
import { easeInOutCubic } from '/machinery/easings'
import { useLocationMatch } from '@kaliber/routing'
import { animated, useSpring } from 'react-spring'
import { useTranslate } from '/machinery/I18n'
import { routeMap } from '/routeMap'

import { Icon } from '/features/buildingBlocks/Icon'

import cssStyleContext from '/cssGlobal/style-context.css'
import mediaStyles from '/cssGlobal/media.css'
import styles from './Introduction.css'

import logoRitualsBlock from '/images/logos/rituals-block.png'
import globeIcon from '/images/icons/globe-stand.raw.svg'
import productsIcon from '/images/icons/products.raw.svg'
import peopleIcon from '/images/icons/people.raw.svg'
import corpIcon from '/images/icons/b-corp.raw.svg'

const DELAY = 2500
const LOGO_STAGGER = DELAY + 150

export function Introduction({ introduction }) {
  const isViewportMd = useMediaQuery(mediaStyles.viewportMd)

  const { params: { language } } = useLocationMatch()
  const logoAnimation = useLogoAnimation({ delay: LOGO_STAGGER })
  const { __ } = useTranslate()

  const { text, metaData } = introduction ?? {}

  const style = useSpring({
    from: { y: `0px` },
    to: { y: `-var(--size-48)` },
    config: { mass: 1, tension: 250, friction: 50 },
    delay: DELAY
  })

  const Heading = isViewportMd
    ? HeadingXs
    : HeadingXxs

  return (
    <animated.div
      className={styles.component}
      data-style-context={cssStyleContext.contextWhite}
      {...{ style }}
    >
      <animated.img
        alt='Rituals logo'
        role='presentation'
        className={cx(styles.blockLogo, styles.blockLogoLayout)}
        style={logoAnimation}
        src={logoRitualsBlock}
      />

      <Heading h='strong' title={text} />

      {Boolean(metaData?.length) && (
        <ul className={styles.metaDataContainer} style={{ '--meta-data-count': `${metaData?.length}` }}>
          {metaData.map(({ _key, icon, description }) => (
            <MetaItem key={_key} {...{ icon, description }} />
          ))}
        </ul>
      )}

      <ButtonGhost
        dataX='link-to-workWithHeartAndSoulPage'
        href={routeMap.app.workWithHeartAndSoul.page({ language })}
        layoutClassName={styles.buttonLayout}
      >
        {__`more-about-us`}
      </ButtonGhost>
    </animated.div>
  )
}

function MetaItem({ icon: iconString, description }) {
  const icon = getIcon({ iconString })

  return (
    <li className={styles.componentMetaItem}>
      <div className={styles.iconContainer}>
        <Icon layoutClassName={styles.iconLayout} {...{ icon }} />
      </div>
      <p>{description}</p>
    </li>
  )
}

function getIcon({ iconString }) {
  switch (iconString) {
    case 'globe': return globeIcon
    case 'people': return peopleIcon
    case 'products': return productsIcon
    case 'corp': return corpIcon
    default: throw new Error(`Selected icon does not exist in 'getIcon' switchcase in 'Introduction.js'`)
  }
}

function useLogoAnimation({ delay }) {
  return useSpring({
    config: { friction: 50, mass: 5, damping: 25, easing: easeInOutCubic },
    from: { opacity: 0, y: `-45%`, x: `-50%`, filter: `blur(3px)`, transform: `scale(0.995)` },
    to: { opacity: 1, y: `-50%`, x: `-50%`, filter: `blur(0px)`, transform: `scale(1)` },
    delay
  })
}
